<template>
    <div id="industryDynamicsDetails">
        <div class="box">
            <div style="font-size: 12px;margin-top: 20px;margin-bottom: 20px;"><span style="color: #ccc;cursor: pointer;" @click="blackIndex">首页</span><span style="color: #333;">>{{TabTitle}}</span> </div>
            <div class="leftBox" v-for="(item,index) in List" :key="index" :style="index==currentSort ? '' : 'display:none;'">
                <div class="titleNmae">
                    <p>{{item.title}}</p> 
                </div>
                <div class="content">
                    <p style="padding-top: 20px;padding-left: 10px;font-size: 14px;font-weight: 400;color: #999999;;">{{item.insertTime}}</p>
                    <p v-if="item.hnsType!=2" v-html="item.content" style="line-height: 50px;padding-top: 20px;padding-left: 10px;padding-right:10px;font-size: 14px;color: #666;width: 90%;height: 80%;">

                    </p>
                </div>
            </div>
            <div class="rightBox">
                <div v-for="(item,index) in List" :key="index" :class="{active:currentSort==index}" class="titleClass" @click="titleBoxClick(index,item)">
                    <div style="height: 40px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;padding: 5px;">{{item.title}}</div>
                    <div :class="{active2:currentSort==index}"  style="font-size: 14px;display: flex;align-items:end;justify-content: end;">{{item.insertTime}}</div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            currentSort:0,
            List:[],
            TabTitle:'',
        })
        const methods = {
            blackIndex(){
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push({name:'index'});
            },
            titleBoxClick(index,item){
                if(item.hnsType==2){
                    window.open(item.content,"_blank")
                }
                state.currentSort = index;
            },
            
        }
        onMounted(()=>{
           state.List = getStorage("List");
           state.TabTitle = getStorage("TabTitle");
           state.currentSort= getStorage("ListIndex");
           methods.titleBoxClick(state.currentSort, getStorage("ListItem"))
        })
        return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
#industryDynamicsDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 72vh;
        margin: 0 auto;
    }
    .leftBox{
        width: 68%;
        height: 100%;
        // background: #fff;
        float: left;
        .titleNmae{
            width: 100%;
            height: 6%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center
        }
        .content{
            width: 100%;
            background: #fff;
            margin-top: 2%;
        }
    }
    .rightBox{
        width: 30%;
        height: 99.5%;
        background: #fff;
        float: right;
        .titleClass{
            background: #F7F8FA;
            width: 90%;
            height: 70px;
            margin: 20px auto;
            cursor: pointer;

        }
            .active{
                background-color:#76C8F6;
                color: #fff;
            }
            .active2{
                color: #fff;
            }
    }
}


</style>